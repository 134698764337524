<template>
    <svg version="1.1" id="insurance" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 150 150" style="enable-background:new 0 0 150 150;" xml:space="preserve">
        <g>
			<g>
				<g>
					<path class="st0" d="M13.43,39.78c2.54,0.39,12.8,4.77,15.34,6.05c0.63,5.54-1.39,21.64,1.14,24.73c3.04,1.29,5.2-1.03,8.24-1.16c1.77,2.83,5.07,5.28,6.97,7.99l3.17-0.26c2.41-4.25,1.01-16.23,1.01-22.16c2.92,0.26,15.85,5.8,17.49,6.96l0.51,59.9c-4.94-0.52-39.68-18.29-46.78-21.51c-2.16-1.03-4.94-2.19-6.85-3.22L13.43,39.78z M33.84,64.9V48.41c2.16,0.13,8.62,2.96,10.4,3.99v16.36c-0.38-0.52-3.04-3.48-3.68-3.99C38.15,63.22,37.14,64.9,33.84,64.9z M53.75,50.73c1.65-1.16,5.32-2.45,7.23-3.22c2.92-1.16,5.45-2.19,8.11-3.35l31.31-12.75c3.17-1.16,13.31,3.61,17.11,4.25c-0.13,0.13-0.25,0.26-0.25,0.39l-1.39,1.16c-0.25,0.26-1.27,0.64-1.9,0.77L70.48,57.17C68.33,57.43,55.52,51.89,53.75,50.73zM39.04,43.77l41.2-15.97c9.13-3.48,5.32-2.32,14.2,0.13c-3.3,2.45-34.74,14.81-41.33,17.39c-5.07,2.06-6.09,3.61-13.06,0C38.91,44.55,39.42,45.19,39.04,43.77z M18.88,35.79l44.75-15.07c3.68-1.16,3.42-1.93,7.73-0.39c2.66,1.03,5.2,1.29,7.35,2.83c-2.54,0.77-4.94,2.19-7.23,2.96c-2.41,0.9-5.2,1.93-7.73,3.09L33.59,40.94c-2.92,1.16-4.94-0.52-7.48-1.55L18.88,35.79zM8.49,35.27v63.12c0.76,2.45,1.52,2.45,3.68,3.48l36.51,17.39c2.92,1.55,19.52,9.79,21.17,9.66l15.34-7.34c2.28-1.67,1.27-2.58-0.13-4.51c-3.68-0.26-9.38,4.25-12.8,5.41c-0.51-6.83-0.76-57.32,0.25-60.28l48.55-21.9c2.28-0.52,0.38-0.26,1.14,0.26c1.65,3.61-0.13,11.85,0.76,16.62l3.93,0.26c0.63-3.99,0.63-19.45,0-23.32l-29.67-9.92c-5.2-1.8-9.76-3.09-14.83-5.02c-18.38-6.96-13.82-4.77-30.3,0.64l-29.79,9.92c-2.16,0.77-4.82,1.67-7.23,2.45C11.4,33.08,10.64,32.82,8.49,35.27z"/>
            		<path class="st0" d="M89.5,72.89c6.59-0.52,13.56-4.25,19.14-7.47l4.31-2.58c0.13-0.13,0.63-0.26,0.76-0.39c0-0.26,0.25-0.26,0.25-0.26c2.03,1.03,3.68,2.45,5.58,3.61c3.42,1.93,8.62,4.51,12.42,5.67c2.92,0.77,4.18,0.9,6.72,1.8c0,8.76,1.39,28.73-2.54,35.42c-2.41,4.12-10.14,8.24-13.82,10.82c-10.14,7.6-8.11,5.8-14.33,1.67l-11.54-7.86c-7.86-5.93-6.85-11.08-6.85-21.25C89.5,85.77,89.37,79.33,89.5,72.89z M84.81,69.15c-0.63,8.76-0.13,19.19-0.13,27.95c0,10.31,2.28,15.07,8.24,19.58c5.58,4.25,11.03,7.6,16.61,11.34c5.2,3.61,4.44,2.83,9.13-0.26c26.75-17.52,24.59-15.2,24.59-44.96c0-17.78,0.76-14.04-5.96-15.07c-5.45-0.9-10.14-3.35-14.83-6.05l-8.37-5.15c-2.54-0.13-11.66,10.69-28.14,11.72L84.81,69.15z"/>
					<path class="st0" d="M122.08,94.53c3.55-3.61,9-7.6,6.97-9.92c-1.01-0.64,1.52-0.77-2.16-0.9c-0.76,0-0.89,0.26-1.52,0.52l-13.82,13.53c-3.04-1.67-6.47-7.73-9.89-8.37c-1.65,0.77-3.04,1.8-1.39,4.25c1.65,2.32,8.24,7.73,9.63,10.31l3.04-0.13L122.08,94.53z"/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
    export default {
        name: "insurance"
    }
</script>

<style scoped>
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FF7A00;}
</style>