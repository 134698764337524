<template>
  <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 150 50" style="enable-background:new 0 0 150 50;" xml:space="preserve">
      <rect y="-0.4" class="st0" width="151" height="50.4"/>
      <path class="st1" d="M131.3,24.8c-2.1,0-4.2,0-6.3,0c-1.6-3.6-3.2-7.1-4.8-10.7c2.1,0,4.2,0,6.3,0C128.1,17.7,129.7,21.3,131.3,24.8z"/>
      <path class="st1" d="M149.3,27.8c-0.1,0.6-0.1,1.2-0.2,1.8c-5.3,0-10.6,0.1-15.9,0.1c0.2-1.1,0.3-2.3,0.5-3.4C139,26.8,144.1,27.3,149.3,27.8z"/>
      <path class="st1" d="M149.3,32h-15.7v8.5h0.8c1-3.2,3.8-5.5,7.2-5.5c3.4,0,6.2,2.3,7.2,5.5h0.5V32z"/>
      <g>
          <path class="st1" d="M142,38.3c-3.2,0-5.8,1.6-5.8,3.7c0,2,2.6,3.7,5.8,3.7c3.2,0,5.8-1.6,5.8-3.7C147.7,39.9,145.1,38.3,142,38.3zM142,43.6c-1.4,0-2.6-0.7-2.6-1.7c0-0.9,1.2-1.7,2.6-1.7c1.4,0,2.6,0.7,2.6,1.7C144.6,42.9,143.4,43.6,142,43.6z"/>
          <ellipse class="st1" cx="141.8" cy="41.9" rx="1.4" ry="0.9"/>
      </g>
      <g>
          <path class="st1" d="M81.8,38.3c-3.6,0-6.5,1.9-6.5,4.4c0,2.4,2.9,4.4,6.5,4.4c3.6,0,6.5-1.9,6.5-4.4C88.3,40.2,85.4,38.3,81.8,38.3z M81.8,44.6c-1.6,0-2.9-0.9-2.9-2c0-1.1,1.3-2,2.9-2c1.6,0,2.9,0.9,2.9,2C84.8,43.7,83.5,44.6,81.8,44.6z"/>
          <path class="st1" d="M81.6,41.6c-0.9,0-1.6,0.5-1.6,1.1c0,0.6,0.7,1.1,1.6,1.1c0.9,0,1.6-0.5,1.6-1.1C83.3,42,82.5,41.6,81.6,41.6z"/>
      </g>
      <path class="st1" d="M109.6,7.3c-0.6-1.4-1.2-2.9-1.9-4.3c-1.2,0-2.5,0-3.7,0c1,2,2.1,4,3.1,6V7.3H109.6z"/>
      <path class="st1" d="M107.6,10.2c0.7-0.4,1.5-0.8,2.2-1.2V7.9c-0.1-0.2-0.2-0.4-0.3-0.6H107V9c0,0,0,0,0,0C107.2,9.4,107.4,9.8,107.6,10.2z"/>
      <rect x="107" y="7.8" class="st1" width="2.9" height="26.8"/>
      <rect x="102.5" y="38.3" class="st1" width="7.2" height="5.6"/>
      <rect x="112.9" y="38.3" class="st1" width="17.6" height="4.7"/>
      <path class="st1" d="M122.2,25.7c0,0-3.1-7.2-5.1-11.9c-1.5,0.1-3,0.2-4.5,0.4v20.1h17.8c0.1-3.7,0.2-7.1,0.3-7.1h-8.5C122.2,26.2,122.2,25.7,122.2,25.7z M120,30.9h-3.4v-3h3.4V30.9z"/>
      <path class="st1" d="M98.7,38.3H88.5v0.6c1.9,1.2,3.1,2.9,3.2,4.8h7.1V38.3z"/>
      <g>
          <path class="st1" d="M36.3,38.3c-3.6,0-6.5,1.9-6.5,4.4c0,2.4,2.9,4.4,6.5,4.4c3.6,0,6.5-1.9,6.5-4.4C42.8,40.2,39.9,38.3,36.3,38.3z M36.3,44.6c-1.6,0-2.9-0.9-2.9-2c0-1.1,1.3-2,2.9-2c1.6,0,2.9,0.9,2.9,2C39.2,43.7,37.9,44.6,36.3,44.6z"/>
          <path class="st1" d="M36.1,41.6c-0.9,0-1.6,0.5-1.6,1.1c0,0.6,0.7,1.1,1.6,1.1c0.9,0,1.6-0.5,1.6-1.1C37.7,42,37,41.6,36.1,41.6z"/>
      </g>
      <path class="st1" d="M17.6,38.3v0.1c0.5,0.2,2.3,0.8,3.4,2.6c0.8,1.2,1,2.4,1,3c1.3,0,2.7,0,4,0c0.1-0.6,0.2-1.8,1-3c1.2-1.9,3-2.5,3.5-2.7H17.6z"/>
      <g>
          <path class="st1" d="M11.5,38.3c-3.6,0-6.5,1.9-6.5,4.4c0,2.4,2.9,4.4,6.5,4.4c3.6,0,6.5-1.9,6.5-4.4C17.9,40.2,15,38.3,11.5,38.3zM11.5,44.6c-1.6,0-2.9-0.9-2.9-2c0-1.1,1.3-2,2.9-2c1.6,0,2.9,0.9,2.9,2C14.4,43.7,13.1,44.6,11.5,44.6z"/>
          <path class="st1" d="M11.3,41.6c-0.9,0-1.6,0.5-1.6,1.1c0,0.6,0.7,1.1,1.6,1.1c0.9,0,1.6-0.5,1.6-1.1C12.9,42,12.2,41.6,11.3,41.6z"/>
      </g>
      <g>
          <g>
              <path class="st1" d="M8,30.9c0-1,0.6-1.9,1.7-2.5v-0.1c-0.6-0.4-1.1-1.1-1.1-2.1c0-0.9,0.6-1.8,1.3-2.3v-0.1c-0.8-0.6-1.6-1.8-1.6-3.2c0-3,2.4-4.5,4.9-4.5c0.7,0,1.3,0.1,1.8,0.3h4.4v2.7h-1.9c0.2,0.4,0.4,1,0.4,1.6c0,2.9-2.1,4.2-4.7,4.2c-0.4,0-0.9-0.1-1.5-0.2c-0.3,0.3-0.4,0.5-0.4,0.9c0,0.6,0.5,0.9,1.8,0.9h1.9c2.9,0,4.6,0.9,4.6,3.2c0,2.6-2.6,4.5-6.7,4.5C10.2,34.1,8,33.2,8,30.9z M16.2,30.3c0-0.8-0.6-1-1.8-1h-1.2c-0.8,0-1.3,0-1.7-0.2c-0.4,0.4-0.6,0.8-0.6,1.2c0,0.9,1,1.4,2.6,1.4C15.1,31.8,16.2,31.1,16.2,30.3z M14.9,20.6c0-1.3-0.7-2-1.6-2s-1.6,0.7-1.6,2c0,1.4,0.7,2.1,1.6,2.1S14.9,22,14.9,20.6z"/>
              <path class="st1" d="M23.3,24.3v-5h-1.6v-2.7l1.8-0.1l0.4-3.3h2.9v3.3h2.9v2.9h-2.9v5c0,1.4,0.6,2,1.5,2c0.4,0,0.8-0.1,1.1-0.2l0.6,2.7c-0.6,0.2-1.5,0.5-2.7,0.5C24.4,29.1,23.3,27.2,23.3,24.3z"/>
              <path class="st1" d="M32.6,22.6c0-4,2.8-6.5,5.6-6.5c3.4,0,5.1,2.6,5.1,6c0,0.7-0.1,1.3-0.1,1.6H36c0.3,1.8,1.5,2.6,3.1,2.6c0.9,0,1.7-0.3,2.6-0.8l1.2,2.2c-1.2,0.9-2.8,1.4-4.2,1.4C35.2,29.1,32.6,26.7,32.6,22.6z M40.3,21.3c0-1.4-0.6-2.3-2-2.3c-1.1,0-2.1,0.8-2.3,2.3H40.3z"/>
              <path class="st1" d="M47,11.3h3.5v10h0.1l3.8-4.9h3.8L54,21.5l4.5,7.3h-3.8L51.9,24l-1.5,1.8v3.1H47V11.3z"/>
              <path class="st1" d="M61.4,11.3h3.5v4.3l-0.2,2.2c0.9-0.8,2.1-1.7,3.7-1.7c2.6,0,3.7,1.9,3.7,5v7.7h-3.5v-7.2c0-1.8-0.5-2.3-1.5-2.3c-0.9,0-1.4,0.4-2.2,1.2v8.4h-3.5V11.3z"/>
              <path class="st1" d="M76.4,16.4h2.9l0.3,1.6h0.1c1-1,2.2-1.9,3.8-1.9c2.6,0,3.7,1.9,3.7,5v7.7h-3.5v-7.2c0-1.8-0.5-2.3-1.5-2.3c-0.9,0-1.4,0.4-2.2,1.2v8.4h-3.5V16.4z"/>
              <path class="st1" d="M90.7,22.6c0-4.1,2.8-6.5,5.8-6.5c3,0,5.8,2.4,5.8,6.5c0,4.1-2.8,6.5-5.8,6.5C93.5,29.1,90.7,26.7,90.7,22.6zM98.7,22.6c0-2.1-0.7-3.5-2.2-3.5c-1.5,0-2.2,1.4-2.2,3.5s0.7,3.5,2.2,3.5C98,26.2,98.7,24.8,98.7,22.6z"/>
          </g>
      </g>
      <rect x="44" y="38.3" class="st1" width="30" height="5.4"/>
  </svg>
</template>

<script>
    export default {
        name: "logo"
    }
</script>

<style scoped>
    .st0{fill:#0F0F0F;}
    .st1{fill:#FFFFFF;}
</style>