import { render, staticRenderFns } from "./Stages.vue?vue&type=template&id=66a8d678&scoped=true&"
import script from "./Stages.vue?vue&type=script&lang=js&"
export * from "./Stages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a8d678",
  null
  
)

export default component.exports