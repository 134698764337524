<template>
    <svg version="1.1" id="document" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
        <g>
			<g>
				<path class="st0" d="M725,198.2L531.5,4.8C528.5,1.7,524.3,0,520,0H149.6c-43.8,0-79.3,35.6-79.3,79.3v641.3c0,43.8,35.6,79.3,79.3,79.3h500.9c43.8,0,79.3-35.6,79.3-79.3V209.8C729.8,205.4,728.1,201.3,725,198.2z M536.3,55.7L674,193.4h-91c-12.5,0-24.2-4.9-33-13.7c-8.8-8.8-13.7-20.5-13.7-33V55.7z M697.1,720.7c0,25.7-20.9,46.7-46.7,46.7H149.6c-25.7,0-46.7-20.9-46.7-46.7V79.3c0-25.7,20.9-46.7,46.7-46.7h354.1v114.1c0,21.2,8.2,41.1,23.2,56.1c15,15,34.9,23.2,56.1,23.2h114.1L697.1,720.7L697.1,720.7z"/>
			</g>
		</g>
        <g>
			<g>
				<path class="st0" d="M429.8,549.2h-7.3c-9,0-16.3,7.3-16.3,16.3s7.3,16.3,16.3,16.3h7.3c9,0,16.3-7.3,16.3-16.3S438.8,549.2,429.8,549.2z"/>
			</g>
		</g>
        <g>
			<g>
				<path class="st0" d="M369.7,549.2H211.1c-9,0-16.3,7.3-16.3,16.3s7.3,16.3,16.3,16.3h158.6c9,0,16.3-7.3,16.3-16.3S378.7,549.2,369.7,549.2z"/>
			</g>
		</g>
        <g>
			<g>
				<path class="st0" d="M588.9,474.6H211.1c-9,0-16.3,7.3-16.3,16.3c0,9,7.3,16.3,16.3,16.3h377.8c9,0,16.3-7.3,16.3-16.3C605.2,481.9,597.9,474.6,588.9,474.6z"/>
			</g>
		</g>
        <g>
			<g>
				<path class="st0" d="M588.9,400H211.1c-9,0-16.3,7.3-16.3,16.3s7.3,16.3,16.3,16.3h377.8c9,0,16.3-7.3,16.3-16.3S597.9,400,588.9,400z"/>
			</g>
		</g>
        <g>
			<g>
				<path class="st0" d="M588.9,325.4H211.1c-9,0-16.3,7.3-16.3,16.3c0,9,7.3,16.3,16.3,16.3h377.8c9,0,16.3-7.3,16.3-16.3S597.9,325.4,588.9,325.4z"/>
			</g>
		</g>
</svg>
</template>

<script>
    export default {
        name: "document"
    }
</script>

<style scoped>
    .st0{fill:#FFFFFF;}
</style>